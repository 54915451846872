import { Accordion, Badge, Button, TabsPrimitive, Typography } from '@cannect/new-components/atoms'
import { Product, QuantityCounter } from '@cannect/new-components/molecules'
import { ProductModalDisabled } from '@cannect/new-components/ProductModalDisabled'
import { ProductModalDisabledNutraceutico } from '@cannect/new-components/ProductModalDisabledNutraceutico'
import BreadCrumb from 'components/BreadCrumb'
import Loading from 'components/Loading'
import { ShoppingCart } from 'lucide-react'

import RelatedProduct from './RelatedProduct'
import { useProductDetails } from './useProductDetails'

export const ProductDetailsUI = ({
  isLoading,
  productDetails,
  handleAddToCartClick,
  handleAddToCart,
  redirectToProductPrescription,
  loadingToCart,
  handleCloseFlavorModal,
  isPrescriber,
  nutraceuticosModal,
  setNutraceuticosModal,
  anvisaModalMessage,
  modalUrlClickButton,
  learnMore,
  prescriber,
  quantity,
  setQuantity,
  flavorModal,
  handleChangeFlavor,
  tokenApi,
  handleGoToLogin,
  isSupplementProduct
}: ReturnType<typeof useProductDetails>) => {
  if (isLoading)
    return (
      <div className="h-screen">
        <Loading loading={isLoading} />
      </div>
    )

  if (!productDetails?.id) return null
  return (
    <main className="w-full max-w-[1200px] px-4">
      <div className="mb-5 flex w-full flex-col items-center justify-between md:mb-0 md:flex-row">
        <BreadCrumb
          paths={[
            { label: 'Produtos', link: '/produtos/1' },
            { label: `SKU: ${productDetails?.id}`, link: '#' }
          ]}
        />
      </div>

      <Product.Root className="grid w-full grid-cols-1 gap-8 lg:grid-cols-2">
        <div>
          <Product.HeaderImageSelo
            src={productDetails?.media?.filter((value) => value.format === 'IMG')?.[0]?.path}
            alt="Imagem do produto padrão"
            isNationalProduct={productDetails?.supplier?.id === 18}
            isSupplementProduct={productDetails?.class?.id === 6}
            className="mx-auto size-full shadow-none"
            product={productDetails}
          />
        </div>

        <div className="flex flex-col items-start gap-4">
          <Typography.Heading
            type="headingFive"
            weight="semibold"
            className="line-clamp-5 max-w-[calc(100%-18px)] md:text-3xl">
            {productDetails?.translated_name || productDetails?.name}
          </Typography.Heading>

          <div className="lg:md-4 mb-2 flex items-center gap-2 text-neutral-500">
            <Typography.Text type="paragraphTwo" muted weight="semibold">
              {productDetails?.brand?.name}
            </Typography.Text>
            <span className="h-1.5 w-1.5 rounded-full bg-neutral-300" />
            <Typography.Text type="paragraphTwo" muted>
              SKU: {productDetails?.id}
            </Typography.Text>
          </div>

          {productDetails?.supplier?.id === 18 && (
            <Badge variant="neutral_dark" rounded="md" className="text-primary-700">
              Entrega de origem nacional, receba em até <span className="font-bold">72 horas</span> após a confirmação
              de pagamento.
            </Badge>
          )}

          {productDetails?.class?.id === 6 && productDetails?.brand?.id === 108 && (
            <Badge variant="success" rounded="md">
              Frete Grátis
            </Badge>
          )}
          {!!tokenApi && (
            <div className="flex items-start gap-2">
              <Product.Price
                currentPrice={productDetails?.real_price}
                promotionalPrice={productDetails?.valid_promotion?.promotional_price}
                className="[&>*]:!text-2xl"
              />

              {productDetails?.valid_promotion?.promotional_price && (
                <Badge variant="neutral" rounded="md" className="mt-1">
                  {productDetails?.promotion?.discount_type === 'percent'
                    ? `-${productDetails?.promotion?.discount_value}%`
                    : `-R$${productDetails?.promotion?.discount_value}`}
                  off
                </Badge>
              )}
            </div>
          )}

          <div className="mb-6">
            <Typography.Text weight="semibold" className="mb-4">
              Sabor
            </Typography.Text>

            <div className="flex flex-wrap gap-4">
              {productDetails?.flavors?.map((item) => {
                const isRecommended = !!item?.isRecommendedFlavor
                const isActive = item.flavor === productDetails?.flavor
                const isLowStock = item?.balance && item?.balance <= 0

                return (
                  item?.status === 'A' && (
                    <div className="flex flex-col justify-end gap-2" key={item.id}>
                      <Typography.Text
                        type="paragraphTwo"
                        className={`max-w-[130px] text-center ${isRecommended ? 'text-warning-600' : 'text-neutral-500'}`}>
                        {/*        eslint-disable-next-line no-nested-ternary */}
                        {isRecommended ? 'Recomendado' : isLowStock ? 'Possível tempo maior de entrega' : ''}
                      </Typography.Text>
                      <Badge
                        variant={isActive ? 'primary_medium' : 'outline_medium'}
                        className={`cursor-pointer ${isRecommended && !isActive ? 'opacity-70' : ''}`}
                        onClick={() => handleChangeFlavor(item?.id)}>
                        {item.flavor}
                      </Badge>
                    </div>
                  )
                )
              })}
            </div>
          </div>

          <div className="mt-6 flex flex-col gap-4 sm:flex-row sm:items-center">
            <div className="flex items-center gap-4">
              {(tokenApi || !isSupplementProduct) && (
                <>
                  <QuantityCounter quantity={quantity} onChangeQuantity={setQuantity} isLoading={loadingToCart} />
                  <Button
                    full
                    size="xl"
                    variant="primary_medium"
                    iconPlacement="right"
                    icon={<ShoppingCart />}
                    isLoading={loadingToCart}
                    onClick={() => handleAddToCartClick(productDetails, quantity)}>
                    Adicionar ao carrinho
                  </Button>
                </>
              )}
              {!tokenApi && isSupplementProduct && (
                <>
                  <Button
                    full
                    size="xl"
                    variant="outline_medium"
                    onClick={() => window.open('https://atendimento.cannect.life/atendimento-cannect', '_blank')}
                    className="sm:flex-1">
                    QUERO CONSULTAR UM MÉDICO
                  </Button>
                  <Button size="xl" full variant="primary_medium" onClick={handleGoToLogin} className="sm:flex-1">
                    fazer login para ver mais
                  </Button>
                </>
              )}
            </div>

            {isPrescriber && tokenApi && (
              <Button
                size="xl"
                full
                variant="outline_medium"
                onClick={redirectToProductPrescription}
                className="sm:flex-1">
                Prescrever esse produto
              </Button>
            )}
          </div>
        </div>
      </Product.Root>
      <ProductModalDisabledNutraceutico open={nutraceuticosModal} handleCancel={() => setNutraceuticosModal(false)} />

      <ProductModalDisabled
        open={flavorModal}
        handleCancel={handleCloseFlavorModal}
        onClick={async () => {
          if (prescriber) {
            redirectToProductPrescription()
          } else {
            handleAddToCart(productDetails?.id, quantity)
          }
        }}
        learnMore={learnMore}
        urlRedirect={modalUrlClickButton}
        message={anvisaModalMessage}
      />

      <div className="mt-10">
        <div className="min-h-[650px] w-full rounded-2xl bg-white">
          <TabsPrimitive.Root defaultValue="general_informations">
            <Typography.Text type="paragraphTwo" className="text-warning-600">
              Novidade
            </Typography.Text>
            <TabsPrimitive.List className="gap-0 border-0 bg-white p-0">
              <TabsPrimitive.Trigger
                className="rounded-none border border-b-0 border-r-0 border-neutral-100 px-6 py-3"
                value="general_informations">
                Ficha geral
              </TabsPrimitive.Trigger>
              {!!tokenApi && (
                <TabsPrimitive.Trigger
                  className="rounded-none border border-b-0 border-neutral-100 px-6 py-3"
                  value="technical_informations">
                  Ficha técnica
                </TabsPrimitive.Trigger>
              )}
              <TabsPrimitive.Trigger
                className="rounded-none border border-b-0 border-l-0 border-neutral-100 px-6 py-3"
                value="coa_informations">
                COA
              </TabsPrimitive.Trigger>
            </TabsPrimitive.List>

            <div className="flex w-full items-start justify-center rounded-2xl rounded-t-none border border-solid border-neutral-100 !bg-white p-4 md:p-8">
              <TabsPrimitive.Content
                value="general_informations"
                className="w-full space-y-4 rounded-2xl bg-neutral-50 px-4 py-8">
                <div className="rounded bg-white px-3 py-2 md:px-8">
                  <Typography.Text>{productDetails?.details}</Typography.Text>
                </div>

                {productDetails?.general_informations &&
                  productDetails?.general_informations.map((item) =>
                    item?.children?.length ? (
                      <Accordion.Root type="single" collapsible key={`${item.title}-${item.description}`}>
                        <Accordion.Item value={item.title}>
                          <Accordion.Trigger className="grid grid-cols-[120px_1fr_auto] gap-2 rounded rounded-b-none bg-white px-3 py-2 hover:bg-neutral-100 hover:no-underline md:grid-cols-[300px_1fr_auto] md:px-8">
                            <Typography.Text type="captionOne" weight="semibold" className="text-start md:text-base">
                              {item.title}
                            </Typography.Text>
                            <div className="flex text-start">
                              <Typography.Text type="captionOne" className="text-start md:text-base">
                                {item.description}
                              </Typography.Text>
                            </div>
                          </Accordion.Trigger>

                          <Accordion.Content>
                            <div className="mt-2 space-y-2 px-2 md:px-4">
                              {item.children.map((child) => (
                                <div
                                  key={`${child.title}-${child.description}`}
                                  className="grid grid-cols-[120px_1fr] items-center gap-2 rounded bg-white px-8 py-2 md:grid-cols-[300px_1fr]">
                                  <Typography.Text type="captionOne" className="md:text-base" weight="semibold">
                                    {child.title}
                                  </Typography.Text>
                                  <Typography.Text type="captionOne" className="md:text-base">
                                    {child.description}
                                  </Typography.Text>
                                </div>
                              ))}
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    ) : (
                      <div
                        key={`${item.title}-${item.description}`}
                        className="grid grid-cols-[120px_1fr] items-center gap-2 rounded bg-white px-3 py-2 md:grid-cols-[300px_1fr] md:px-8">
                        <Typography.Text type="captionOne" className="md:text-base" weight="semibold">
                          {item.title}
                        </Typography.Text>
                        <Typography.Text type="captionOne" className="md:text-base">
                          {item.description}
                        </Typography.Text>
                      </div>
                    )
                  )}
              </TabsPrimitive.Content>
              {productDetails?.curatorship?.Variabilidade?.['Variabilidade entre CoA e embalagem'] && (
                <TabsPrimitive.Content
                  value="technical_informations"
                  className="w-full space-y-4 rounded-2xl bg-neutral-50 px-4 py-8">
                  {Object.entries(productDetails?.curatorship || {}).map(
                    ([category, items]: [string, Record<string, any>]) => (
                      <Accordion.Root type="single" collapsible key={`${category}`}>
                        <Accordion.Item value={category}>
                          <Accordion.Trigger className="grid grid-cols-[120px_1fr_auto] gap-2 rounded rounded-b-none bg-white px-3 py-2 hover:bg-neutral-100 hover:no-underline md:grid-cols-[300px_1fr_auto] md:px-8">
                            <Typography.Text type="captionOne" weight="semibold" className="text-start md:text-base">
                              {category}
                            </Typography.Text>
                            <div className="flex text-start" />
                          </Accordion.Trigger>
                          <Accordion.Content>
                            <div className="mt-2 space-y-2 px-2 md:px-4">
                              {Object.entries(items).map(([title, description]) => (
                                <div
                                  key={`${title}-${description}`}
                                  className="grid grid-cols-[120px_1fr] items-center gap-2 rounded bg-white px-8 py-2 md:grid-cols-[300px_1fr]">
                                  <Typography.Text type="captionOne" className="md:text-base" weight="semibold">
                                    {title}
                                  </Typography.Text>
                                  <Typography.Text type="captionOne" className="md:text-base">
                                    {(() => {
                                      if (typeof description === 'string') return description
                                      if (description === true) return 'Sim'
                                      return '-'
                                    })()}
                                  </Typography.Text>
                                </div>
                              ))}
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    )
                  )}
                </TabsPrimitive.Content>
              )}
              <TabsPrimitive.Content value="coa_informations" className="w-full">
                <iframe src={productDetails?.coa_url} title="COA" className="min-h-[650px] w-full min-w-full" />
              </TabsPrimitive.Content>
            </div>
          </TabsPrimitive.Root>
        </div>
      </div>

      <RelatedProduct filters={`class_id=${productDetails?.class?.id}&shape_id=${productDetails?.shape?.id}`} />
    </main>
  )
}
