import React, { useEffect, useState } from 'react'
import { FirstAidKit } from 'phosphor-react'
import { Input, DatePicker, DatePickerProps, ConfigProvider } from 'antd'
import FilterDropdown from 'new-components/MarketPlace/FilterDropdown'
import { filterMobile } from 'assets/svg'
import { useConsultation } from 'pages/MyQueries/hooks/useConsultation'
import CardQueries from '../CardQueries'
import { useQueries } from 'pages/MyQueries/hooks/useQueries'
import Pagination from 'components/Pagination'
import { useDebouncedSearch } from 'pages/MyQueries/hooks/useDebounceSearch'
import locale from 'antd/es/locale/pt_BR'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { CONSULTATION_FILTER } from 'pages/MyQueries/Mocks/ListAppointments'
import * as Styles from './styles'

export interface AppointmentFormProps {
  filter: string
  scheduling_date: string | null
  scheduling_time: string | null
  coupon: string
  amountWithDiscount: string
  address: {
    cep: string
    city: string
    address: string
    number: string
    state: string
  }
  payment: {
    card_holder_name: string
    card_month_validate: string
    card_year_validate: string
    card_number: string
    card_cvv: string
    payment_method: string
  }
}

function ListAppointments() {
  const { groupConsultationsByYear } = useConsultation()
  const { consultations, getConsultationList, loadingConsultation, reloadList, count } = useQueries()
  const [loading, setLoading] = useState(false)
  const groupedConsultations = groupConsultationsByYear(consultations)
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [searchInput, setSearchInput] = useState('')
  const [sortFilter, setSortFilter] = useState({ title: '', value: '' })
  const limit = 6

  const handleSearch = async ({ term, sortFilter }: { term?: any; sortFilter?: string }) => {
    setLoading(true)
    await getConsultationList({ limit, filterDate: sortFilter, search: term, page })
    setLoading(false)
  }

  const { searchTerm, handleSearchChange } = useDebouncedSearch((term: string) => {
    handleSearch({ term })
    setSelectedDate(null)
  }, 1000)

  useEffect(() => {
    handleSearch({ term: searchTerm })
  }, [reloadList, page])

  const handleDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      handleSearch({ term: dateString })
      setSelectedDate(date)
      setSortFilter({ title: '', value: '' })
      setSearchInput('')
    }
  }

  const handleClearDate = () => {
    handleSearch({ term: '' })
    setSelectedDate(null)
  }

  const handleSortFilter = ({ title, value }: { title: string; value: string }) => {
    handleSearch({ sortFilter: value })
    setSelectedDate(null)
    setSortFilter({ title, value })
    setSearchInput('')
  }

  const handleChangeInput = (value: string) => {
    setSearchInput(value)
    handleSearchChange(value)
    setSelectedDate(null)
    setSortFilter({ title: '', value: '' })
  }

  return (
    <Styles.Wrapper>
      <Styles.Heading>
        <FirstAidKit />
        Minhas consultas
      </Styles.Heading>

      <Styles.FilterBox>
        <Styles.SearchBox>
          <Input
            placeholder="Pesquisar consultas"
            disabled={loading}
            value={searchInput}
            onChange={(e) => handleChangeInput(e.target.value)}
          />
        </Styles.SearchBox>

        <Styles.Filters>
          <FilterDropdown
            title="Todas"
            imageIcon={filterMobile}
            data={CONSULTATION_FILTER}
            selectedValue={sortFilter}
            onChangeValue={handleSortFilter}
          />
          <Styles.Separator />
          <ConfigProvider locale={locale}>
            <DatePicker
              placeholder="Busca por data"
              value={selectedDate}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              bordered={false}
              allowClear={{ clearIcon: <AiOutlineCloseCircle onClick={handleClearDate} /> }}
            />
          </ConfigProvider>
          <Styles.Separator />
          {consultations.length} consultas
        </Styles.Filters>
      </Styles.FilterBox>

      {loadingConsultation ? (
        <Styles.LoadingContent>Loading</Styles.LoadingContent>
      ) : (
        <ConsultationGroups groupedConsultations={groupedConsultations} />
      )}

      <Styles.PaginateBox>
        {count > 1 && (
          <Pagination
            forcePage={page - 1}
            onPageChange={({ selected }: any) => setPage(selected + 1)}
            pageCount={count}
          />
        )}
      </Styles.PaginateBox>
    </Styles.Wrapper>
  )
}

function ConsultationGroups({ groupedConsultations }: { groupedConsultations: any }) {
  return (
    <Styles.ListAppointments>
      {Object.entries(groupedConsultations)
        .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
        .map(([year, consultations]: any) => (
          <Styles.GroupedConsultationsList key={year}>
            <h2>{year}</h2>

            {(consultations || []).map((item: any) => (
              <CardQueries key={item.id} {...item} />
            ))}
          </Styles.GroupedConsultationsList>
        ))}
    </Styles.ListAppointments>
  )
}

export default ListAppointments
