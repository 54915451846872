/* eslint-disable import/no-extraneous-dependencies */

import { useEffect, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useScheduling } from 'hooks/useScheduling'
import useSignedUser from 'hooks/useSignedUser'
import { useTokenApi } from 'hooks/useTokenApi'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { CreditCardFormSchema, PixFormSchema } from './schedulingMock'
import * as Styles from './styles'
import { IAppointmentForm, ParamsProps } from './types'
import {
  bannerAgendamentoMarco,
  bannerAgendamentoMarcoMobile,
  bannerMarco,
  bannerProdutosMarcoMobile
} from '@cannect/assets/img'
import { MOBILE_WIDTH } from '@cannect/utils/constants'
import useMediaQuery from '@cannect/hooks/useMediaQuery'

function AppointmentScheduling() {
  const {
    step,

    currentStep,
    getScheduling,
    appointmentFromB2C,
    selectedPaymentMethod,
    checkoutActiveStep
  } = useScheduling()
  const { cart_id } = useParams<ParamsProps>()
  const { signedUser } = useSignedUser()
  const { tokenApi } = useTokenApi()
  const [promotionalModal, setPromotionalModal] = useState(false)
  const PROMOTIONAL_MODAL = 'PROMOTIONAL_MESSAGE'
  const promotionalAltText = 'Mês do acesso - Use o cupom ACESSO75 e garanta desconto de R$ 75 na consulta'
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const createAppointmentForm = useForm<IAppointmentForm>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: selectedPaymentMethod === 'pix' ? zodResolver(PixFormSchema) : zodResolver(CreditCardFormSchema),
    defaultValues: {
      filter: '',
      search_date: null,
      scheduling_date: null,
      scheduling_time: '',
      coupon: '',
      amountWithDiscount: undefined,
      creditAmountWithDiscount: undefined,
      agreeWithTerms: false,
      patient: null,
      prescriber: null,
      address: {
        cep: '',
        city: '',
        address: '',
        number: '',
        state: ''
      },
      payment: {
        card_holder_name: '',
        card_validate: '',
        card_number: '',
        card_cvv: ''
      }
    }
  })

  const { setValue } = createAppointmentForm

  useEffect(() => {
    if (cart_id) {
      const uuid = cart_id.split('=')[1]
      getScheduling(uuid)
    }
  }, [cart_id])

  useEffect(() => {
    if (appointmentFromB2C) {
      setValue('scheduling_date', appointmentFromB2C?.date)
      setValue('scheduling_time', appointmentFromB2C?.hour)
      setValue('amountWithDiscount', parseFloat(appointmentFromB2C?.amount_with_discount))
      setValue('creditAmountWithDiscount', parseFloat(appointmentFromB2C?.credit_amount_with_discount))
      if (appointmentFromB2C?.coupon?.name) setValue('coupon', appointmentFromB2C?.coupon?.name || '')
    }
  }, [appointmentFromB2C])

  useEffect(() => {
    if (tokenApi && signedUser) {
      setValue('patient', signedUser)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    document?.getElementById('page-wrapper')?.scrollTo(0, 0)
    document?.getElementById('main-app-container')?.scrollTo(0, 0)
  }, [currentStep, checkoutActiveStep])

  const handleCloseInstableModal = () => {
    localStorage.setItem(PROMOTIONAL_MODAL, `${promotionalModal}`)
    setPromotionalModal(false)
  }

  const initPromotionalModal = () => {
    const promotionalModal = localStorage.getItem(PROMOTIONAL_MODAL)
    if (!promotionalModal) {
      setPromotionalModal(true)
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={isMobile ? bannerAgendamentoMarcoMobile : bannerAgendamentoMarco}
          alt={promotionalAltText}
          style={{ width: '80%', maxHeight: '400px', margin: '20px' }}
        />
      </div>
      <Styles.Container className={`${currentStep !== 0 ? 'mx-auto max-w-[1366px]' : ''}`}>
        <Styles.TabContainer>
          <FormProvider {...createAppointmentForm}>{step}</FormProvider>
        </Styles.TabContainer>
        {/* <Modal isOpen={promotionalModal} setOpenModal={handleCloseInstableModal} bannerType="static">
          <Styles.PromotionalImage src={PromotionalBannerDesktop} alt={promotionalAltText} />
        </Modal> */}
      </Styles.Container>
    </>
  )
}

export default AppointmentScheduling
