import React, { useState, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Radio } from 'antd'
import * as Styled from '../styles'
import { EconomyText, MethodBox, MethodsWrapper, PaymentError, SubmitButton } from './styles'
import PixIcon from 'assets/svg/PixIcon'
import { temporaryTheme } from 'styles/theme'
import CreditCardMethod, { CreditCard } from './CreditCardMethod'
import CouponInput from './CouponInput'
import PixMethod from './PixMethod'
import Button from 'new-components/Button'
import { useScheduling } from 'hooks/useScheduling'
import useMediaQuery from 'hooks/useMediaQuery'
import api from 'services/api'
import { getEvents } from 'services/events'
import { formatToBRL } from 'utils/formatters'
import { calculateDifferenceBetweenValues } from 'pages/AppointmentScheduling/schedulingUtils'
import { MOBILE_WIDTH } from 'utils/constants'
import { IAddress, IAppointmentForm, IPayment } from 'pages/AppointmentScheduling/types'
import { isToday as isTodayDateFns, parseISO } from 'date-fns'
import Modal from 'new-components/Modal'

const CHECK_PAYMENT_INTERVAL = 5000

interface PaymentPayload {
  address: IAddress
  payment: IPayment
  coupon?: string
  prescriber_user_id?: number | null
  cpf?: string
}

const PaymentMethod: React.FC = () => {
  const {
    appointmentFromB2C,
    setAppointmentFromB2C,
    nextCheckoutStep,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    portalAppointment,
    setLoading,
    shouldUpdateQrCode,
    setShouldUpdateQrCode
  } = useScheduling()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { handleSubmit, getValues, trigger, watch } = useFormContext<IAppointmentForm>()
  const [showWarningModal, setShowWarningModal] = useState(false)

  const amountWithDiscountWatch = watch('amountWithDiscount')
  const creditAmountWithDiscountWatch = watch('creditAmountWithDiscount')
  const patientWatch = watch('patient')
  const selectedPrescriber = watch('prescriber')

  const [qrCode, setQrCode] = useState<string | null>(null)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [paymentError, setPaymentError] = useState<string | null>(null)
  const [creditCard, setCreditCard] = useState<CreditCard>({
    card_cvv: '',
    card_month_validate: '01',
    card_year_validate: new Date().getFullYear().toString(),
    installments: 1
  })

  const checkPaymentInterval = useRef<number | null>(null)
  const [eventSent, setEventSent] = useState(false)

  const processPayment = async (appointmentId: number, payload: any, paymentMethod: string) => {
    try {
      setLoading(true)
      setLoadingPayment(true)
      const response = await api.post(`/scheduling/appointment/payment/${appointmentId}`, payload)
      setPaymentError(null)
      return response
    } catch (error: any) {
      toast.error(`Erro ao processar pagamento.`)
      setPaymentError(error.response?.data.message || 'Erro desconhecido')
      setLoading(false)
      return null
    } finally {
      if (paymentMethod !== 'credit_card') {
        setLoading(false)
      }
      setLoadingPayment(false)
    }
  }

  const generatePayload = (
    selectedPaymentMethod: string,
    address: IAddress,
    payment?: IPayment,
    coupon?: string
  ): PaymentPayload => {
    const payload: PaymentPayload = {
      address: { ...address },
      payment: {
        payment_method: selectedPaymentMethod,
        ...creditCard, // Inclui installments aqui
        installments: creditCard.installments // Adiciona o campo installments explicitamente
      },
      prescriber_user_id: appointmentFromB2C ? appointmentFromB2C?.prescriber?.user?.id : selectedPrescriber?.user?.id,
      cpf: appointmentFromB2C ? appointmentFromB2C?.patient?.person?.cpf : patientWatch?.cpf
    }

    if (selectedPaymentMethod === 'credit_card' && payment?.card_validate) {
      const [card_month_validate, card_year_validate] = payment.card_validate.split('/')
      payload.payment = {
        ...payment,
        payment_method: selectedPaymentMethod,
        card_month_validate,
        card_year_validate,
        installments: creditCard.installments // Certifica-se de incluir installments aqui também
      }
      delete payload.payment.card_validate
    }

    if (coupon) {
      payload.coupon = coupon
    }

    return payload
  }

  const handlePaymentStatusCheck = async (appointmentId: number, eventData: any) => {
    const statusResponse = await api.get(`/scheduling/appointment/${appointmentId}`)
    if (statusResponse.data.success) {
      const currentAppointment = statusResponse.data.appointment
      const isPaymentOk =
        currentAppointment?.current_status?.status_description.name === 'paid' ||
        currentAppointment?.current_status?.status_description.name === 'waiting_appointment'
      if (isPaymentOk && !eventSent) {
        setLoading(false)
        setAppointmentFromB2C(currentAppointment)
        getEvents.eventSendDataLayer(eventData)
        setEventSent(false)
        nextCheckoutStep()
        getEvents.eventLoadObjects({
          name: 'agendamento_sucesso',
          action: 'agendamento_sucesso',
          category: 'agendamento'
        })
        if (checkPaymentInterval.current) {
          clearInterval(checkPaymentInterval.current)
        }
      }
    }
  }

  const isToday = (date: string | null) => {
    if (!date) return false
    return isTodayDateFns(parseISO(date))
  }

  const handlePayment = async (paymentMethod: string) => {
    const { coupon, address, payment, scheduling_date } = getValues()

    if (scheduling_date && isToday(scheduling_date)) {
      setShowWarningModal(true)
      return
    }

    const validFields = await trigger()
    if (!validFields) {
      toast.error('Preencha todos os campos obrigatórios')
      return
    }

    const paymentPayload = generatePayload(paymentMethod, address, payment, coupon)
    const appointment = appointmentFromB2C ?? portalAppointment
    if (!appointment) return

    const paymentResponse = await processPayment(appointment.id, paymentPayload, paymentMethod)
    const eventData = {
      eventName: 'appointment_success',
      appointmentId: portalAppointment?.id,
      prescriber_id: portalAppointment?.prescriber_id,
      patient_id: portalAppointment?.patient_id,
      payment_method: paymentPayload.payment.payment_method || '',
      value:
        paymentPayload.payment.payment_method !== 'credit_card'
          ? portalAppointment?.amount
          : portalAppointment?.credit_amount
    }

    if (paymentResponse && paymentResponse.data.success) {
      if (paymentMethod === 'pix') setQrCode(paymentResponse.data.appointment.qr_code)
      if (checkPaymentInterval.current) {
        clearInterval(checkPaymentInterval.current)
      }
      checkPaymentInterval.current = window.setInterval(() => {
        handlePaymentStatusCheck(appointment.id, eventData)
      }, CHECK_PAYMENT_INTERVAL)
    }
  }

  const handleConfirmPayment = async () => {
    setShowWarningModal(false)
    const { coupon, address, payment } = getValues()
    const validFields = await trigger()
    if (!validFields) {
      toast.error('Preencha todos os campos obrigatórios')
      return
    }

    const paymentPayload = generatePayload(selectedPaymentMethod, address, payment, coupon)
    const appointment = appointmentFromB2C ?? portalAppointment
    if (!appointment) return

    const paymentResponse = await processPayment(appointment.id, paymentPayload, selectedPaymentMethod)
    const eventData = {
      eventName: 'appointment_success',
      appointmentId: portalAppointment?.id,
      prescriber_id: portalAppointment?.prescriber_id,
      patient_id: portalAppointment?.patient_id,
      payment_method: paymentPayload.payment.payment_method || '',
      value:
        paymentPayload.payment.payment_method !== 'credit_card'
          ? portalAppointment?.amount
          : portalAppointment?.credit_amount
    }

    if (paymentResponse && paymentResponse.data.success) {
      if (selectedPaymentMethod === 'pix') setQrCode(paymentResponse.data.appointment.qr_code)
      if (checkPaymentInterval.current) {
        clearInterval(checkPaymentInterval.current)
      }
      checkPaymentInterval.current = window.setInterval(() => {
        handlePaymentStatusCheck(appointment.id, eventData)
      }, CHECK_PAYMENT_INTERVAL)
    }
  }

  const handleSelectPaymentMethod = async (paymentMethod: string) => {
    setPaymentError(null)
    if (paymentMethod === 'pix') {
      const validFields = await trigger(['address', 'agreeWithTerms'])

      if (validFields) {
        setSelectedPaymentMethod('pix')
        handlePayment('pix')
      }
      return
    }
    setSelectedPaymentMethod('credit_card')
    if (checkPaymentInterval.current) {
      clearInterval(checkPaymentInterval.current)
    }
  }

  useEffect(() => {
    return () => {
      if (checkPaymentInterval.current) {
        clearInterval(checkPaymentInterval.current)
      }
    }
  }, [])

  useEffect(() => {
    if (shouldUpdateQrCode && qrCode) {
      handleSelectPaymentMethod('pix')
      setShouldUpdateQrCode(false)
    }
  }, [shouldUpdateQrCode, qrCode])

  const onSubmit = () => {
    handlePayment('credit_card')
  }

  const isCreditCardSelected = selectedPaymentMethod === 'credit_card'

  return (
    <Styled.Box style={{ gridArea: 'payment', marginTop: `${isMobile ? '0' : '-86px'}` }}>
      <Styled.BoxTitle>Meio de pagamento</Styled.BoxTitle>

      <CouponInput />

      <MethodsWrapper>
        <MethodBox selected={isCreditCardSelected} onClick={() => handleSelectPaymentMethod('credit_card')}>
          <Radio onChange={() => handleSelectPaymentMethod('credit_card')} checked={isCreditCardSelected}>
            Cartão de crédito
          </Radio>
          <span>{formatToBRL(creditAmountWithDiscountWatch)}</span>
        </MethodBox>

        <MethodBox selected={!isCreditCardSelected} onClick={() => handleSelectPaymentMethod('pix')}>
          <Radio onChange={() => handleSelectPaymentMethod('pix')} checked={!isCreditCardSelected}>
            Pix <PixIcon color={temporaryTheme.colors.darkGrey} size={16} />
            <EconomyText>
              economize{' '}
              <span>
                R$ {calculateDifferenceBetweenValues(creditAmountWithDiscountWatch, amountWithDiscountWatch)}{' '}
              </span>
              pagando no pix
            </EconomyText>
          </Radio>
          <span>{formatToBRL(amountWithDiscountWatch)}</span>
        </MethodBox>
      </MethodsWrapper>
      {paymentError && !isCreditCardSelected && (
        <PaymentError>
          <p>Erro ao realizar pagamento.</p>
          <Button onClick={() => handlePayment(selectedPaymentMethod)}>Tentar novamente</Button>
        </PaymentError>
      )}

      {isCreditCardSelected && (
        <CreditCardMethod
          creditAmount={creditAmountWithDiscountWatch}
          creditCard={creditCard}
          setCreditCard={setCreditCard}
        />
      )}

      {!isCreditCardSelected && !paymentError && <PixMethod qrCode={qrCode} loadingPayment={loadingPayment} />}

      {isCreditCardSelected && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <SubmitButton type="submit">finalizar pagamento</SubmitButton>
        </form>
      )}

      <Modal
        isOpen={showWarningModal}
        setOpenModal={setShowWarningModal}
        title="Atenção"
        returnAction={handleConfirmPayment}
        returnText="Confirmar">
        <Styled.ModalContent>
          <p>
            ATENÇÃO: Estou ciente que ao confirmar o agendamento da consulta para hoje, não será possível cancelar ou
            reagendar para outra data.
          </p>
          <Button
            type="button"
            onClick={() => {
              setShowWarningModal(false)
              handleConfirmPayment()
            }}>
            Confirmar
          </Button>
        </Styled.ModalContent>
      </Modal>
    </Styled.Box>
  )
}

export default PaymentMethod
