/* eslint-disable import/no-duplicates */
import { format, parseISO, isSameDay } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import Modal from 'new-components/Modal'
import { useValidations } from 'pages/MyQueries/hooks/useValidations'
import { Consultation } from 'pages/MyQueries/types/appointment'
import { CalendarBlank } from 'phosphor-react'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { PiStethoscopeLight } from 'react-icons/pi'
import { useHistory } from 'react-router'

import { formatReal } from 'utils/formatters'
import { redirectLink } from 'utils/redirectLink'
import When from 'utils/when'
import { EvalueteQuery, TabContentModalQueries } from '../Modals'
import { useValidateActions } from './hooks/useValidateActions'

import * as Styles from './styles'

function CardQueries({
  prescriber,
  current_status,
  amount,
  date,
  hour,
  uuid,
  id,
  event_link,
  recipe_link,
  nps,
  createdAt
}: Consultation) {
  const { professional_name, include_return_visit, return_period, return_visit_value } = prescriber
  const { status_description } = current_status
  const { isWithinByHours } = useValidations()
  const history = useHistory()
  const [cancelModal, setCancelModal] = useState(false)
  const [evalueteModal, setEvalueteModal] = useState(false)
  const [tabSelect, setTabSelect] = useState<'info' | 'reschedule' | 'rescheduled'>('info')
  const consultationDateTime = parseISO(`${date}T${hour}`)
  const formattedDayOfWeek = format(consultationDateTime, 'EEEE', { locale: ptBR })
  const formattedDate = format(consultationDateTime, "dd/MM 'às' HH:mm", { locale: ptBR })
  const isFinished = isWithinByHours(date, hour, 0.3)
  const { ButtonProps, ValidateLinkActions } = useValidateActions(status_description.name, isFinished.finished, !!nps)
  const { setValue } = useFormContext()

  const isCreatedToday = createdAt && isSameDay(parseISO(createdAt), consultationDateTime)

  const resetData = () => {
    setValue('scheduling_date', null)
  }

  const handleTab = (value: 'info' | 'reschedule') => {
    setCancelModal(true)
    setTabSelect(value)
  }

  const handleCloseModal = () => {
    setCancelModal(false)
    resetData()
  }

  const handleActionButton = () => {
    if (isFinished.finished) {
      redirectLink(`/agendamento`)
    }
    if (status_description.name === 'waiting_payment') {
      redirectLink(`/agendamento/cart_id=${uuid}`)
    } else if (status_description.name === 'waiting_appointment') {
      redirectLink(event_link)
    } else {
      history.push('/agendamento')
    }
  }

  return (
    <Styles.Wrapper status={status_description.name}>
      <Styles.InfoBox className="info">
        <Styles.IconBox>
          <PiStethoscopeLight size={32} />
        </Styles.IconBox>

        <Styles.DataDescriptionBox>
          <Styles.Name>{professional_name}</Styles.Name>
          <Styles.Category>
            {(prescriber.prescriber_expertises || [])
              .slice(0, 3)
              .map((expertise: any) => expertise.name)
              .join(' • ')}
          </Styles.Category>
          <When expr={!!include_return_visit}>
            <Styles.RescheduleValidate>
              Retorno válido até {return_period} dias após a data de agendamento, pelo valor de{' '}
              {formatReal(return_visit_value)}.
            </Styles.RescheduleValidate>
          </When>
        </Styles.DataDescriptionBox>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <CalendarBlank />
        <Styles.DateBox>
          <p>
            {formattedDayOfWeek}, <br />
            {formattedDate}
          </p>
        </Styles.DateBox>

        <Styles.AppointMentBox>
          <p>
            Consulta online
            <br /> {formatReal(amount)}
          </p>
        </Styles.AppointMentBox>
      </Styles.InfoBox>

      <Styles.ButtonActions>
        <Styles.Button color={ButtonProps.color} onClick={handleActionButton}>
          {ButtonProps.text}
        </Styles.Button>
      </Styles.ButtonActions>

      {!isCreatedToday && (
        <Styles.LinkActions>
          <ValidateLinkActions
            cancelAction={() => handleTab('info')}
            rescheduleAction={() => handleTab('reschedule')}
            evalueteAction={() => setEvalueteModal(true)}
            onMyPrescription={() => redirectLink(recipe_link || '')}
            withPrescription={!!recipe_link}
          />
        </Styles.LinkActions>
      )}

      <Modal isOpen={cancelModal} setOpenModal={handleCloseModal} bgColor=" #f2f2f2">
        <TabContentModalQueries
          date={date}
          hour={hour}
          value={tabSelect}
          handleTab={(tab) => setTabSelect(tab)}
          prescriberInfos={prescriber}
          appointmentId={id}
          closeModal={handleCloseModal}
        />
      </Modal>

      <Modal isOpen={evalueteModal} setOpenModal={setEvalueteModal}>
        <EvalueteQuery
          nps_values={nps}
          prescriber_name={prescriber.professional_name}
          appointment_id={id}
          onClose={() => setEvalueteModal(false)}
        />
      </Modal>
    </Styles.Wrapper>
  )
}

export default CardQueries
