import { useCannectAssistant } from '@cannect/hooks/useCannectAssistant'
import { usePrepareCart } from '@cannect/hooks/usePrepareCart'
import useSignedUser from '@cannect/hooks/useSignedUser'
import { useTokenApi } from '@cannect/hooks/useTokenApi'
import { TProductDetailItem, useGetProductById } from '@cannect/services/resources/products'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Texts, RedirectUrls } from '../../constants/texts'

export const useProductDetails = () => {
  const params = useParams() as any
  const productId = params?.id

  const { tokenApi } = useTokenApi()
  const history = useHistory()
  const location = useLocation()

  const { addToCart } = usePrepareCart()
  const { signedUser } = useSignedUser()
  const { prescription, setPath, setStep, addProductToPrescription } = useCannectAssistant()

  const { data, isFetching: isLoading } = useGetProductById(productId)

  const productDetails = useMemo(() => data?.product, [data?.product])

  const fromLocation = {
    from: {
      pathName: location.pathname
    }
  }

  useEffect(() => {
    const normalizeText = (text: string) => text?.trim().replace(/\s+/g, '-').replace(/-+/g, '-')

    if (productDetails) {
      const brandName = normalizeText(productDetails.brand?.name || '')
      const productName = normalizeText(
        productDetails.name?.replace('/', '-').replace('(', '-').replace(')', '-') || ''
      )

      const newUrl = `/detalhes-produtos/${brandName}/${productName}/${productId}`
      if (window.location.pathname !== newUrl) {
        history.push(newUrl, { replace: true })
      }
    }
  }, [productDetails, productId, history])

  const [quantity, setQuantity] = useState(1)
  const [flavorModal, setFlavorModal] = useState(false)
  const [nutraceuticosModal, setNutraceuticosModal] = useState(false)
  const [anvisaModalMessage, setAnvisaModalMessage] = useState<string>('')
  const [modalUrlClickButton, setModalUrlClickButton] = useState<string>('')
  const [learnMore, setLearnMore] = useState<boolean>(false)
  const [prescriber, setPrescriber] = useState<boolean>(false)
  const [loadingToCart, setLoadingToCart] = useState(false)
  const isAssistantFlow = useMemo(() => location.pathname.startsWith('/produtos_assistente'), [location])

  const handleGoToLogin = () => {
    history.push({
      pathname: '/login',
      state: { from: { pathname: location.pathname } }
    })
  }

  const handleAddToCart = async (id: number, quantity: number) => {
    setLoadingToCart(true)
    try {
      await addToCart(id, quantity)
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingToCart(false)
    }
  }

  const handleAddToCartClick = async (product?: TProductDetailItem, quantity?: number) => {
    if (!product?.id || !quantity) return

    const listProductAttendant = [15668]
    const productAttendantCannaRiver = listProductAttendant.includes(product?.id)
    const listProductDisabledCannaRiver: string | any[] = []
    const productDisabledCannaRiver = listProductDisabledCannaRiver.includes(product?.id)
    const productsCannaRiver = [99]
    const productsNexBiopharma = [109]
    const verifyNexBiopharma = product?.brand?.id && productsNexBiopharma.includes(product?.brand?.id)
    const verifyCannaRiver = product?.brand?.id && productsCannaRiver.includes(product?.brand?.id)

    if (productDisabledCannaRiver) {
      setFlavorModal(true)
      setModalUrlClickButton(RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION)
      setLearnMore(false)
      setAnvisaModalMessage(
        'Atenção, cliente! Neste momento, os produtos da linha Canna River podem ter um prazo de entrega maior que o habitual. Você pode adicionar o produto ao carrinho ou entrar em contato com o nosso time de vendas para mais informações.'
      )
      return
    }

    if (productAttendantCannaRiver) {
      setFlavorModal(true)
      setModalUrlClickButton(RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION)
      setLearnMore(true)
      setAnvisaModalMessage(
        'No momento, este produto está disponível para importação apenas com nossa equipe de atendimento.'
      )
      return
    }

    if (verifyNexBiopharma) {
      setFlavorModal(true)
      setModalUrlClickButton(RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION)
      setLearnMore(false)
      setAnvisaModalMessage(Texts.NEX_BIOPHARMA_MESSAGE)
      return
    }

    if (verifyCannaRiver && !isAssistantFlow) {
      setFlavorModal(true)
      setModalUrlClickButton(RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION)
      setLearnMore(false)
      setAnvisaModalMessage(Texts.PURE_JAMAICA_MESSAGE)
      return
    }

    handleAddToCart(product?.id, quantity)
  }

  const redirectToProductPrescription = () => {
    if (!productDetails) return

    setPrescriber(true)

    const productsNexBiopharma: any = [109]
    const verifyNexBiopharma = productsNexBiopharma.includes(productDetails?.brand?.id)

    if (verifyNexBiopharma && !flavorModal) {
      setFlavorModal(true)
      setModalUrlClickButton(RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION)
      setLearnMore(false)
      setAnvisaModalMessage(Texts.NEX_BIOPHARMA_MESSAGE)
      return
    }

    setPath('alreadyKnow')
    setStep(2)
    const isProductAlreadyAdded = !!prescription.products.find((p) => p.id === productDetails.id)
    if (!isProductAlreadyAdded) {
      // @ts-expect-error type error, fix in function
      addProductToPrescription(productDetails)
    }
    history.push('/assistente')
  }

  const handleCloseFlavorModal = () => {
    setFlavorModal(false)
  }

  const handleChangeFlavor = (flavorId: number) => {
    if (!flavorId) return

    const currentPath = location.pathname
    const newPath = currentPath.replace(/\/\d+(?=[^/]*$)/, `/${flavorId}`)
    history.push(newPath)
  }

  const isPrescriber =
    signedUser?.prescriber?.council === 'CRM' ||
    signedUser?.prescriber?.council === 'CRO' ||
    signedUser?.prescriber?.council === 'RMS'

  const isSupplementProduct = productDetails?.class?.id === 6

  return {
    fromLocation,
    productDetails,
    isLoading,
    redirectToProductPrescription,
    handleCloseFlavorModal,
    isPrescriber,
    nutraceuticosModal,
    setNutraceuticosModal,
    anvisaModalMessage,
    modalUrlClickButton,
    learnMore,
    prescriber,
    loadingToCart,
    setLoadingToCart,
    handleAddToCartClick,
    handleAddToCart,
    quantity,
    setQuantity,
    flavorModal,
    setFlavorModal,
    handleChangeFlavor,
    isSupplementProduct,
    tokenApi,
    handleGoToLogin
  }
}
