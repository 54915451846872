import { z } from 'zod'

const minLengthMessage = 'Digite no mínimo 3 caracteres'
const emptyFieldMessage = 'Preencha este campo'

export const validateCep = /^\d{5}-?\d{3}$/

export const AddressSchema = z.object({
  cep: z
    .string({
      required_error: emptyFieldMessage,
      invalid_type_error: emptyFieldMessage
    })
    .regex(validateCep, 'CEP inválido'),

  state: z
    .string({
      required_error: emptyFieldMessage,
      invalid_type_error: emptyFieldMessage
    })
    .min(1, emptyFieldMessage),

  city: z
    .string({
      required_error: emptyFieldMessage,
      invalid_type_error: emptyFieldMessage
    })
    .min(1, emptyFieldMessage)
    .min(3, minLengthMessage),

  address_number: z
    .string({
      required_error: emptyFieldMessage,
      invalid_type_error: emptyFieldMessage
    })
    .min(1, emptyFieldMessage),

  street: z
    .string({
      required_error: emptyFieldMessage,
      invalid_type_error: emptyFieldMessage
    })
    .min(1, emptyFieldMessage)
    .min(3, minLengthMessage),

  district: z
    .string({
      required_error: emptyFieldMessage,
      invalid_type_error: emptyFieldMessage
    })
    .min(1, emptyFieldMessage)
    .min(3, minLengthMessage),

  complement: z.string().optional(),
  reference_address: z.string().optional(),
})

export type AddressSchemaType = z.infer<typeof AddressSchema>
