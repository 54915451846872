import { useScheduling } from 'hooks/useScheduling'
import { IAppointment } from 'hooks/useScheduling/types'
import Button from 'new-components/Button'
import { IAppointmentForm } from 'pages/AppointmentScheduling/types'
import { isToday as isTodayDateFns, parseISO } from 'date-fns'

import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import api from 'services/api'
import styled from 'styled-components'
import AppointmentDetails from '../PaymentStep/AppointmentDetails'
import { NavigateButtonsWrapper, StepTitle } from '../styles'
import Banner from '@cannect/components/Banner'

interface IApiResponse {
  success: boolean
  appointment: IAppointment
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 565px;
  padding-bottom: 24px;
`

export default function ReviewStep() {
  const { setCheckoutFormState, back, nextCheckoutStep, setLoading, setPortalAppointment } = useScheduling()
  const { getValues, watch } = useFormContext<IAppointmentForm>()

  const handleBack = () => {
    back()
    setCheckoutFormState('simplified')
  }

  const selectedPrescriber = watch('prescriber')

  const isToday = (date: string | null) => {
    if (!date) return false
    return isTodayDateFns(parseISO(date))
  }

  const handleCreateAppointment = async () => {
    const { scheduling_date, scheduling_time, patient } = getValues()

    const appointmentPayload = {
      origin: 'portal',
      patient_id: patient?.id,
      prescriber_id: selectedPrescriber?.id,
      date: scheduling_date,
      hour: scheduling_time,
      duration: selectedPrescriber?.appointment_duration,
      prescriber_user_id: selectedPrescriber && selectedPrescriber?.user?.id,
      cpf: patient?.cpf || null,
      rne: patient?.rne || null,
      origin_scheduling_id: 1
    }

    try {
      setLoading(true)
      const response = await api.post<IApiResponse>('/scheduling/appointment', appointmentPayload)

      if (response.data.success) {
        setPortalAppointment(response.data.appointment)
        nextCheckoutStep()
        return response.data.appointment
      }
    } catch (error: any) {
      toast.error(`Erro processar agendamento: ${error.response?.data.message || 'Erro desconhecido'}`)
    } finally {
      setLoading(false)
    }
    return null
  }

  const handleContinueToPayment = () => {
    handleCreateAppointment()
  }

  const { scheduling_date } = getValues()
  const showWarning = scheduling_date && isToday(scheduling_date)

  return (
    <Container>
      <StepTitle>
        Revise os detalhes do <span>seu agendamento:</span>
      </StepTitle>
      <AppointmentDetails showHeader={false} />
      {showWarning && (
        <Banner title="ATENÇÃO" variant="warning">
          <p>
            Em caso de agendamento de consulta para a data de hoje, não será possível cancelar ou reagendar para outra
            data.
          </p>
        </Banner>
      )}
      <NavigateButtonsWrapper>
        <Button type="button" isOutlined background="black" onClick={handleBack}>
          Voltar
        </Button>
        <Button type="button" background="green" onClick={handleContinueToPayment}>
          Continuar para o pagamento
        </Button>
      </NavigateButtonsWrapper>
    </Container>
  )
}
