import { useEffect } from 'react'
import { useProductDetails } from './useProductDetails'

export const RedirectPageProduct = () => {
  const { productDetails } = useProductDetails()
  useEffect(() => {
    if (productDetails) {
      const brandName = productDetails.brand?.name?.trim().replace(/\s+/g, '-').replace(/-+/g, '-') || ''
      const productName = productDetails.name?.trim().replace(/\s+/g, '-').replace(/-+/g, '-') || ''
      const productId = productDetails.id

      const newUrl = `/detalhes-produtos/${brandName}/${productName}/${productId}`
      if (window.location.pathname !== newUrl) {
        window.location.href = newUrl
      }
    }
  }, [])
  return null
}
